@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.reviews {
  &__intro {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(56);
  }

  &__texts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    gap: rem(48);
    align-items: stretch;
    max-width: 806px;
    width: 100%;
  }

  &__statistics {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    gap: rem(112);
    margin-bottom: rem(56);
    padding: rem(48) 0;
  }

  &__statistic {
    padding-block: rem(24);
    padding-inline: rem(60) 0;
    border-left: 1px solid var(--color-dark);
  }

  &__num {
    margin-bottom: 12px;
  }

  &__slider {
    max-width: 604px;
    width: 100%;
    margin-inline: auto 0;
  }

  &__slide {
    p {
      margin-bottom: 16px;
    }
  }

  &__author {
    padding-right: rem(90);
    font-size: 16px;
    line-height: 1.23;
    font-weight: var(--fw-500);
    text-transform: uppercase;
  }

  &__slider-arrows {
    position: absolute;
    bottom: -8px;
    right: 0;
    display: flex;
    gap: 24px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__desc {
    max-width: 471px;
    width: 100%;
    margin-right: 20px;
  }

  &__items {
    max-width: 666px;
    width: 100%;
  }

  &__item {
    &:not(:last-of-type) {
      margin-bottom: rem(35);
    }
  }

  &--testimonials {
    h2 {
      margin-bottom: 12px;
    }

    .reviews__author {
      margin-bottom: 16px;
    }
  }

  &--single {
    text-align: center;

    .reviews__author {
      margin-bottom: 24px;
      padding-right: 0;
    }

    .desc {
      max-width: 800px;
      width: 100%;
      margin: 0 auto rem(56);
    }
  }

  @include media(tablet) {
    padding: rem(44) 0;

    &__intro {
      flex-direction: column;
      margin-bottom: rem(36);
    }

    h2 {
      margin-bottom: rem(36);
    }

    &__texts {
      max-width: 100%;
    }

    &__statistics {
      gap: rem(36);
      margin-bottom: rem(36);
      padding: rem(36) 0;
    }

    &__statistic {
      padding-left: rem(24);
    }

    &__slider {
      margin: 0 auto;
      padding-bottom: 18px;
    }

    &__slides {
      &.swiper-wrapper {
        align-items: stretch;
      }
    }

    &--single {
      padding-top: 0;

      .desc {
        margin-bottom: rem(36);
      }
    }
  }

  @include media(mobile-l) {
    &__texts {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      gap: rem(36);
    }

    &__statistics {
      grid-template-columns: repeat(2, 1fr);
      width: 80%;
      margin: 0 auto rem(36);
    }

    &--testimonials {
      .reviews__content {
        flex-direction: column;
      }

      .reviews__desc {
        max-width: 100%;
        margin-bottom: rem(36);
        margin-right: 0;
      }

      h2 {
        margin-bottom: 16px;
      }

      .reviews__items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: rem(36) 16px;
      }

      .reviews__item {
        &:not(:last-of-type) {
          margin-bottom: 0;
        }
      }
    }
  }

  @include media(mobile-m) {
    &--testimonials {
      .reviews__items {
        grid-template-columns: 1fr;
      }
    }
  }

  @include media(mobile-s) {
    &__statistics {
      width: 100%;
      gap: rem(36) 18px;
    }
  }
}