@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.contacts {
  &__items {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    max-width: 597px;
    width: 100%;
  }

  &__image {
    max-width: 667px;
    width: 100%;
    margin-right: 24px;

    img {
      object-fit: contain;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: rem(36);
      padding-bottom: rem(36);
      border-bottom: 1px solid var(--color-gray-250);
    }
  }

  &__name {
    margin-right: rem(36);
  }

  @include media(mobile-l) {
    &__items {
      flex-direction: column;
    }

    &__image {
      margin-right: 0;
      margin-bottom: rem(44);
    }

    &__content {
      max-width: 100%;
    }
  }

  @include media(mobile-s) {
    &__info {
      flex-direction: column;
      align-items: flex-start;

      &:not(:last-of-type) {
        padding-bottom: 16px;
      }
    }

    &__name {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
}