/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */

:root {
  --content-width: 1320px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Avenir Next Cyr", sans-serif;

  // font-weight
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  // color
  --color-white: #fff;
  --color-black: #111111;

  --color-dark: #312d2b;

  --color-gray-100: #fcfcfa;
  --color-gray-200: #b5aba1;
  --color-gray-224: #b5aba13d;
  --color-gray-250: rgba(181, 171, 161, 0.5);

  //  radius
  --radius-main: 100px;

  //gradient
  --gradient-main: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 8.21%, rgba(0, 0, 0, 0.42) 25.27%, rgba(0, 0, 0, 0.1) 81.5%, rgba(0, 0, 0, 0.2) 100%);
  --gradient-secondary: linear-gradient(180deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 63.9%, rgba(0, 0, 0, 0.82) 78.19%, rgba(0, 0, 0, 0.82) 100%);
  --gradient-services: linear-gradient(180deg, rgba(0, 0, 0, 0) 70.04%, rgba(0, 0, 0, 0.57) 84.26%, rgba(0, 0, 0, 0.76) 100%);

  //icons
  --icon-arrow: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L8.29289 9.70711C8.68342 10.0976 9.31658 10.0976 9.70711 9.70711L17.7071 1.70711C18.0976 1.31658 18.0976 0.683418 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L9 7.58579L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893Z' fill='%231B1B1B'/%3E%3C/svg%3E%0A");
}