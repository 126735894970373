@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.policy {
  h1 {
    margin-bottom: rem(48);
    font-size: clamp(34px, 3.5rem + 1vi, 56px);
    line-height: 1.23;
    color: var(--color-dark);
  }

  .title {
    margin-bottom: rem(36);
  }

  p:not(:last-of-type) {
    margin-bottom: 16px;
  }

  a {
    font-weight: var(--fw-600);
    color: var(--color-gray-200);
    text-decoration: underline;
  }

  ul {
    margin-bottom: rem(36);
    font-weight: 500;
  }

  li {
    position: relative;
    padding-left: 30px;

    &::before {
      content: '✓';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  @include media(tablet) {
    h1 {
      font-size: clamp(32px, 2rem + 1vi, 44px);
      margin-bottom: rem(36);
    }
  }
}