@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

h1,
h2,
h3 {
  overflow: hidden;
}

h1,
h2 {
  font-weight: var(--fw-400);
}

h1 {
  font-size: clamp(42px, 3.5rem + 1vi, 64px);
  line-height: 1.19;
  color: var(--color-gray-100);
}

h2 {
  font-size: clamp(34px, 3.5rem + 1vi, 56px);
  line-height: 1.23;
  color: var(--color-dark);
}

h3,
.name {
  font-size: clamp(18px, 2rem + 1vi, 24px);
  line-height: 1.5;
  font-weight: var(--fw-600);
  color: var(--color-dark);
}

p,
li {
  line-height: 1.4;
  font-weight: var(--fw-300);
  color: var(--color-dark);
}

p,
b,
li {
  font-size: clamp(18px, 0.5rem + 1vi, 20px);
}

.desc {
  font-size: 16px;
}

.accent {
  font-size: clamp(42px, 3rem + 1vi, 64px);
  line-height: 1;
  font-weight: var(--fw-400);
  color: var(--color-dark);
}

a,
button {
  font-size: 16px;
  line-height: 1.23;
  font-weight: var(--fw-400);
  color: var(--color-dark);
}

.date,
input,
textarea,
.copyright {
  font-size: 16px;
  line-height: 1.4;
  font-weight: var(--fw-300);
  color: var(--color-dark);
}

.date {
  display: inline-block;
  color: var(--color-gray-100);
}

input,
textarea {
  ::placeholder {
    color: var(--color-gray-200);
  }
}

@include media(tablet) {
  h1,
  .accent {
    font-size: clamp(40px, 2.5rem + 1vi, 52px);
  }

  h2 {
    font-size: clamp(32px, 2rem + 1vi, 44px);
  }

  h3,
  .name {
    font-size: clamp(18px, 1rem + 1vi, 20px);
  }
}