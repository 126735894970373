@font-face {
  src: url("../AvenirNextCyr-Light.39c07127.ttf") format("woff2");
  font-family: Avenir Next Cyr;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../AvenirNextCyr-Regular.992368bb.ttf") format("woff2");
  font-family: Avenir Next Cyr;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../AvenirNextCyr-Medium.3b6af0d7.ttf") format("woff2");
  font-family: Avenir Next Cyr;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../AvenirNextCyr-Demi.84916796.ttf") format("woff2");
  font-family: Avenir Next Cyr;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

:root {
  --content-width: 1320px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Avenir Next Cyr", sans-serif;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --color-white: #fff;
  --color-black: #111;
  --color-dark: #312d2b;
  --color-gray-100: #fcfcfa;
  --color-gray-200: #b5aba1;
  --color-gray-224: #b5aba13d;
  --color-gray-250: #b5aba180;
  --radius-main: 100px;
  --gradient-main: linear-gradient(180deg, #0003 8.21%, #0000006b 25.27%, #0000001a 81.5%, #0003 100%);
  --gradient-secondary: linear-gradient(180deg, #0000001f 0%, #0000001f 63.9%, #000000d1 78.19%, #000000d1 100%);
  --gradient-services: linear-gradient(180deg, #0000 70.04%, #00000091 84.26%, #000000c2 100%);
  --icon-arrow: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L8.29289 9.70711C8.68342 10.0976 9.31658 10.0976 9.70711 9.70711L17.7071 1.70711C18.0976 1.31658 18.0976 0.683418 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L9 7.58579L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893Z' fill='%231B1B1B'/%3E%3C/svg%3E%0A");
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

@media only screen and (max-width: 992px) {
  .main {
    padding-top: var(--header-height);
  }
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  color: var(--color-gray-700);
  background-color: var(--color-gray-100);
  font-optical-sizing: auto;
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1.5;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

.page--no-hero .main {
  padding-top: var(--header-height);
}

.page--no-hero .header:not(.scroll) .logo__img {
  filter: invert() sepia(0%) saturate(0%) hue-rotate(263deg) brightness(102%) contrast(103%);
}

.page--no-hero .header:not(.scroll) .nav__link {
  color: var(--color-dark);
}

.page--no-hero .header:not(.scroll) .nav__link:hover, .page--no-hero .header:not(.scroll) .nav__link.true {
  border-bottom: 1px solid var(--color-dark);
}

@media only screen and (max-width: 992px) {
  .page--no-hero .header:not(.scroll) .logo__img {
    filter: none;
  }

  .page--no-hero .header:not(.scroll) .nav__link {
    color: var(--color-gray-100);
  }
}

section {
  padding: 5.375rem 0;
}

@media only screen and (max-width: 992px) {
  section {
    padding: 2.75rem 0;
  }
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  z-index: 1;
  background: var(--gradient-main);
  position: absolute;
  inset: 0;
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  overflow: hidden;
}

h1, h2 {
  font-weight: var(--fw-400);
}

h1 {
  color: var(--color-gray-100);
  font-size: clamp(42px, 3.5rem + 1vi, 64px);
  line-height: 1.19;
}

h2 {
  color: var(--color-dark);
  font-size: clamp(34px, 3.5rem + 1vi, 56px);
  line-height: 1.23;
}

h3, .name {
  font-size: clamp(18px, 2rem + 1vi, 24px);
  line-height: 1.5;
  font-weight: var(--fw-600);
  color: var(--color-dark);
}

p, li {
  line-height: 1.4;
  font-weight: var(--fw-300);
  color: var(--color-dark);
}

p, b, li {
  font-size: clamp(18px, .5rem + 1vi, 20px);
}

.desc {
  font-size: 16px;
}

.accent {
  font-size: clamp(42px, 3rem + 1vi, 64px);
  line-height: 1;
  font-weight: var(--fw-400);
  color: var(--color-dark);
}

a, button {
  font-size: 16px;
  line-height: 1.23;
  font-weight: var(--fw-400);
  color: var(--color-dark);
}

.date, input, textarea, .copyright {
  font-size: 16px;
  line-height: 1.4;
  font-weight: var(--fw-300);
  color: var(--color-dark);
}

.date {
  color: var(--color-gray-100);
  display: inline-block;
}

input ::placeholder, textarea ::placeholder {
  color: var(--color-gray-200);
}

@media only screen and (max-width: 992px) {
  h1, .accent {
    font-size: clamp(40px, 2.5rem + 1vi, 52px);
  }

  h2 {
    font-size: clamp(32px, 2rem + 1vi, 44px);
  }

  h3, .name {
    font-size: clamp(18px, 1rem + 1vi, 20px);
  }
}

.header {
  z-index: 10;
  width: 100%;
  background-color: #0000;
  padding: 20px 0;
  transition-property: transform, padding, background-color;
  transition-duration: .15s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-100%);
}

.header.scroll {
  background-color: var(--color-dark);
}

@media only screen and (max-width: 992px) {
  .header {
    background-color: var(--color-dark);
  }
}

@media only screen and (max-width: 768px) {
  .header {
    padding: 10px 0;
  }
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav img {
  height: auto;
}

.nav .logo {
  max-width: 140px;
  width: 100%;
  margin-right: 3.75rem;
}

.nav__inner {
  max-width: 943px;
  width: 100%;
  justify-content: space-between;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__inner {
    left: 0;
    top: calc(var(--header-height) * 1);
    z-index: -1;
    max-width: 100%;
    height: calc(var(--vh));
    background-color: var(--color-dark);
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 16px;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    overflow-y: auto;
    transform: translateX(150%);
  }
}

@media only screen and (max-width: 475px) {
  .nav__inner {
    padding: 16px;
  }
}

.nav__list {
  align-items: center;
  margin: 0 20px 0 0;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__list {
    z-index: -1;
    flex-direction: column;
    margin-right: 0;
  }
}

.nav__item:not(:last-of-type) {
  margin-right: 2.375rem;
}

@media only screen and (max-width: 992px) {
  .nav__item:not(:last-of-type) {
    margin-right: 0;
  }

  .nav__item {
    margin-bottom: 30px;
  }
}

.nav__link {
  color: var(--color-gray-100);
  text-transform: uppercase;
  transition: border-bottom .3s ease-in-out;
}

.nav__link.true, .nav__link:hover {
  border-bottom: 1px solid var(--color-gray-100);
}

.nav__btns {
  align-items: center;
  gap: 1.5rem;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__btns {
    flex-direction: column;
    gap: 30px;
  }
}

[class].btn {
  border: 2px solid var(--color-gray-200);
  color: var(--color-gray-200);
  text-align: center;
  border-radius: var(--radius-main);
  background-color: #0000;
  margin: 0;
  padding: 10px 24px;
  transition-property: background-color, color, border-color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
  display: inline-block;
}

[class].btn--reverse, [class].btn:hover {
  color: var(--color-gray-100);
  background-color: var(--color-gray-200);
}

[class].btn--reverse:hover {
  color: var(--color-gray-200);
  background-color: #0000;
}

[class].btn--underline {
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: 100%;
  color: var(--color-dark);
  letter-spacing: .15rem;
  text-transform: uppercase;
  border: 1px solid #0000;
  border-bottom: 1px solid var(--color-dark);
  border-radius: 0;
  margin: 0 auto;
  padding: 11px 0;
  font-size: 14px;
  line-height: 1.22;
  transition-property: border, padding;
  display: block;
}

[class].btn--underline:hover {
  color: var(--color-dark);
  border: 1px solid var(--color-dark);
  background-color: #0000;
  padding: 11px;
}

[class].swiper-button {
  width: 16px;
  height: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: static;
}

[class].swiper-button-prev {
  background-image: url("../images/icons/arrow-prev.svg");
}

[class].swiper-button-next {
  background-image: url("../images/icons/arrow-next.svg");
}

[class].swiper-pagination {
  justify-content: space-between;
  padding: 0 1.75rem;
  display: flex;
  bottom: 16px;
}

[class].swiper-pagination-bullet {
  width: 5.625rem;
  height: 1px;
  background-color: var(--color-gray-100);
  opacity: .36;
  border-radius: 0;
}

[class].swiper-pagination-bullet-active {
  opacity: 1;
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-gray-100);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

.hero {
  padding: 0;
}

.hero__inner {
  min-height: 900px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: flex-end;
  padding: 12.125rem 0;
  display: flex;
  overflow: hidden;
}

.hero__content {
  z-index: 1;
  margin-inline: 3.75rem 0;
}

.hero h1 {
  margin-bottom: 2.25rem;
}

.hero__subtitle {
  color: var(--color-gray-100);
  margin-bottom: 12.1875rem;
}

.hero__links {
  max-width: 1320px;
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.hero__link a {
  color: var(--color-gray-100);
  border-bottom: 1px solid #0000;
  transition: border-bottom .3s ease-in-out;
}

.hero__link a:hover {
  border-bottom: 1px solid var(--color-gray-100);
}

.hero__link a:not(:last-of-type) {
  margin-right: 16px;
}

.hero--pages .hero__inner {
  min-height: 650px;
  padding: 12.1875rem 0 2.25rem;
}

.hero--pages h1 {
  margin-bottom: 1rem;
}

.hero--services {
  padding-bottom: 8rem;
}

.hero--projects .hero__inner {
  padding: 12.1875rem 0 3.375rem;
}

.hero--request .hero__inner {
  min-height: 500px;
  justify-content: flex-start;
  padding: 10.375rem 0 0;
}

.hero--request h2 {
  color: var(--color-gray-100);
  margin-bottom: 16px;
  line-height: 1.2;
}

.hero--request .btn {
  color: var(--color-gray-100);
  border-bottom-color: var(--color-gray-100);
  margin: 0;
}

.hero--request .btn:hover {
  color: var(--color-gray-100);
  border: 1px solid var(--color-gray-100);
}

.hero--project .hero__inner {
  justify-content: space-between;
  padding: 18.5rem 0 2.25rem;
}

.hero--project h1 {
  margin-bottom: 12.875rem;
}

.hero--project .hero__links {
  justify-content: flex-start;
  gap: 5.375rem;
}

.hero--project .name {
  color: var(--color-gray-100);
}

.hero--project sup {
  font-size: 14px;
}

@media only screen and (max-width: 1180px) {
  .hero__inner, .hero--pages .hero__inner, .hero--project .hero__inner {
    min-height: 450px;
    padding: 12.125rem 16px 5.375rem;
  }
}

@media only screen and (max-width: 992px) {
  .hero__inner {
    justify-content: center;
    padding: 3.75rem 16px;
  }

  .hero__content {
    margin-left: 0;
  }

  .hero--pages .hero__inner {
    justify-content: flex-end;
    padding: 3.75rem 16px 24px;
  }

  .hero--services {
    padding: 0;
  }

  .hero--request .hero__inner {
    min-height: 350px;
    justify-content: center;
    padding: 2.75rem 16px;
  }

  .hero--project .hero__inner {
    min-height: 250px;
    padding-top: 6.25rem;
  }

  .hero--project .hero__content {
    min-height: inherit;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    display: flex;
  }

  .hero--project h1 {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .hero--project .hero__links {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    gap: 16px;
  }

  .hero--project .hero__link {
    width: auto;
  }

  .hero--project .name {
    font-size: 14px;
  }
}

@media only screen and (max-width: 576px) {
  .hero__links {
    flex-wrap: wrap;
    gap: 1.125rem 0;
  }

  .hero__link {
    width: 45%;
    text-align: center;
  }
}

.services h2 {
  margin-bottom: 3.5rem;
}

.services__grid {
  border-top: 1px solid var(--color-gray-250);
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  place-items: stretch stretch;
  gap: 16px;
  margin-bottom: 3.5rem;
  padding-top: 2.25rem;
  display: grid;
}

.services__article {
  min-height: 450px;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1.5rem;
  display: flex;
  position: relative;
}

.services .overlay:before {
  z-index: -1;
}

.services__img {
  z-index: -2;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.services__name {
  font-size: 16px;
  line-height: 1.4;
  font-weight: var(--fw-300);
  color: var(--color-gray-100);
  border: 1px solid var(--color-gray-100);
  border-radius: var(--radius-main);
  padding: 4px 16px;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.services__object {
  color: var(--color-gray-100);
  margin-bottom: 8px;
}

.services .desc {
  color: var(--color-gray-100);
}

.services__link:before {
  content: "";
  z-index: 2;
  position: absolute;
  inset: 0;
}

.services--projects, .services--project, .services--contacts {
  padding: 5.375rem 0;
}

.services--projects .overlay:before {
  background: var(--gradient-services);
  z-index: -1;
}

.services--project .services__grid {
  margin-bottom: 0;
}

.services--project .services__img {
  position: static;
}

.services--contacts h2 {
  width: 90%;
  letter-spacing: .05rem;
  border-top: 1px solid var(--color-gray-250);
  margin-bottom: 12px;
  padding-top: 2.25rem;
  line-height: 1.19;
}

.services--contacts .desc {
  margin-bottom: 2.25rem;
}

.services--contacts .btn {
  margin: 0;
}

.services--contacts h2 {
  width: 90%;
  letter-spacing: .05rem;
  border-top: 1px solid var(--color-gray-250);
  margin-bottom: 12px;
  padding-top: 2.25rem;
  line-height: 1.19;
}

.services--contacts .desc {
  color: var(--color-dark);
  margin-bottom: 2.25rem;
}

.services--contacts .btn {
  margin: 0;
}

@media only screen and (max-width: 992px) {
  .services h2 {
    margin-bottom: 1.125rem;
  }

  .services--projects, .services--project, .services--contacts {
    padding: 2.75rem 0;
  }

  .services--project {
    padding-top: 0;
  }

  .services--project .services__img {
    height: auto;
    aspect-ratio: 5 / 3;
  }

  .services--contacts h2 {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .services__grid {
    grid-template-columns: 1fr;
    margin-bottom: 2.25rem;
  }
}

@media only screen and (max-width: 475px) {
  .services__article {
    min-height: 350px;
    padding: 5.375rem 16px 16px;
  }
}

.about {
  padding: 8rem 0 5.375rem;
}

.about__inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.about__intro {
  max-width: 573px;
  width: 100%;
  margin-right: 1.875rem;
}

.about h2 {
  margin-bottom: 2.25rem;
}

.about__slider {
  max-width: 470px;
  width: 100%;
  margin: 0;
}

.about__block:not(:last-of-type) {
  margin-bottom: 3rem;
}

.about__block h2 {
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 0;
  transition-property: padding-left, margin-bottom;
  transition-duration: .6s;
  transition-timing-function: ease-in-out;
  position: relative;
}

.about__block h2:before {
  content: "";
  height: 2px;
  width: 76px;
  background-color: var(--color-dark);
  transition-property: transform;
  transition-duration: .4s;
  transition-timing-function: ease-in;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%)translateX(-100%);
}

.about__block h2:hover {
  margin-bottom: 16px;
  padding-left: 100px;
  transition-duration: .4s;
}

.about__block h2:hover:before {
  background-color: var(--color-dark);
  transition-duration: .6s;
  transform: translateY(-50%)translateX(0);
}

.about__block .about__content {
  max-height: 0;
  opacity: 0;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  transition: max-height .2s ease-in-out .4s, opacity .2s ease-in-out .4s;
  display: flex;
  overflow: hidden;
}

.about__block .about__content img {
  opacity: 0;
  transition: opacity 1.2s ease-in-out;
}

.about__block h2:hover + .about__content {
  max-height: 1000px;
  visibility: visible;
  opacity: 1;
  transition-duration: .8s;
}

.about__block h2:hover + .about__content img {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .about__block:not(:last-of-type) {
    margin-bottom: 2.25rem;
  }

  .about__block h2 {
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 1.5rem;
    padding-left: 60px;
    transition: none;
    position: relative;
  }

  .about__block h2:before {
    width: 50px;
    background-color: var(--color-dark);
    transition: none;
    transform: translateY(-50%)translateX(0);
  }

  .about__block h2:hover {
    margin-bottom: 1.5rem;
    padding-left: 60px;
    transition: none;
  }

  .about__block h2:hover:before {
    transition: none;
    transform: translateY(0)translateX(0);
  }

  .about__block .about__content {
    max-height: 100%;
    opacity: 1;
    gap: 1.5rem;
    transition: none;
    overflow: visible;
  }

  .about__block .about__content img {
    opacity: 1;
    transition: none;
  }

  .about__block h2:hover + .about__content {
    transition: none;
  }
}

@media only screen and (max-width: 768px) {
  .about__block .about__content {
    flex-direction: column;
  }

  .about__block img {
    width: 100%;
    height: auto;
    aspect-ratio: 4 / 2;
  }
}

@media only screen and (max-width: 475px) {
  .about__block h2 {
    padding-bottom: 10px;
    padding-left: 16px;
  }

  .about__block h2:before {
    bottom: 0;
    top: unset;
    width: 55%;
    transform: translateY(0);
  }
}

.about--services {
  border-top: 1px solid var(--color-gray-250);
  padding: 3rem 0;
}

.about--services#sheds {
  padding-bottom: 5.375rem;
}

.about--services .about__intro {
  max-width: 722px;
}

.about--services .about__texts p:not(:last-of-type) {
  margin-bottom: 2.25rem;
}

.about--hovered {
  padding: 3.5rem 0;
}

@media only screen and (max-width: 992px) {
  .about {
    padding: 2.75rem 0;
  }

  .about br {
    display: none;
  }

  .about__inner {
    flex-direction: column;
  }

  .about__intro, .about__image, .about__img {
    max-width: 100%;
    width: 100%;
  }

  .about__intro {
    margin-right: 0;
  }

  .about__intro:not(:last-of-type) {
    margin-bottom: 2.25rem;
  }

  .about__img {
    height: auto;
    aspect-ratio: 5 / 2;
  }

  .about--services .about__intro, .about--services .about__slider, .about--services .about__img {
    max-width: 100%;
    width: 100%;
  }

  .about--services#sheds {
    padding-bottom: 2.75rem;
  }
}

@media only screen and (max-width: 576px) {
  .about--services .about__img {
    height: auto;
    aspect-ratio: 5 / 3;
  }
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.reviews__intro {
  justify-content: space-between;
  margin-bottom: 3.5rem;
  display: flex;
}

.reviews__texts {
  max-width: 806px;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  align-items: stretch;
  gap: 3rem;
  display: grid;
}

.reviews__statistics {
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  gap: 7rem;
  margin-bottom: 3.5rem;
  padding: 3rem 0;
  display: grid;
}

.reviews__statistic {
  border-left: 1px solid var(--color-dark);
  padding-block: 1.5rem;
  padding-inline: 3.75rem 0;
}

.reviews__num {
  margin-bottom: 12px;
}

.reviews__slider {
  max-width: 604px;
  width: 100%;
  margin-inline: auto 0;
}

.reviews__slide p {
  margin-bottom: 16px;
}

.reviews__author {
  font-size: 16px;
  line-height: 1.23;
  font-weight: var(--fw-500);
  text-transform: uppercase;
  padding-right: 5.625rem;
}

.reviews__slider-arrows {
  gap: 24px;
  display: flex;
  position: absolute;
  bottom: -8px;
  right: 0;
}

.reviews__content {
  justify-content: space-between;
  display: flex;
}

.reviews__desc {
  max-width: 471px;
  width: 100%;
  margin-right: 20px;
}

.reviews__items {
  max-width: 666px;
  width: 100%;
}

.reviews__item:not(:last-of-type) {
  margin-bottom: 2.1875rem;
}

.reviews--testimonials h2 {
  margin-bottom: 12px;
}

.reviews--testimonials .reviews__author {
  margin-bottom: 16px;
}

.reviews--single {
  text-align: center;
}

.reviews--single .reviews__author {
  margin-bottom: 24px;
  padding-right: 0;
}

.reviews--single .desc {
  max-width: 800px;
  width: 100%;
  margin: 0 auto 3.5rem;
}

@media only screen and (max-width: 992px) {
  .reviews {
    padding: 2.75rem 0;
  }

  .reviews__intro {
    flex-direction: column;
    margin-bottom: 2.25rem;
  }

  .reviews h2 {
    margin-bottom: 2.25rem;
  }

  .reviews__texts {
    max-width: 100%;
  }

  .reviews__statistics {
    gap: 2.25rem;
    margin-bottom: 2.25rem;
    padding: 2.25rem 0;
  }

  .reviews__statistic {
    padding-left: 1.5rem;
  }

  .reviews__slider {
    margin: 0 auto;
    padding-bottom: 18px;
  }

  .reviews__slides.swiper-wrapper {
    align-items: stretch;
  }

  .reviews--single {
    padding-top: 0;
  }

  .reviews--single .desc {
    margin-bottom: 2.25rem;
  }
}

@media only screen and (max-width: 768px) {
  .reviews__texts {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 2.25rem;
  }

  .reviews__statistics {
    width: 80%;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto 2.25rem;
  }

  .reviews--testimonials .reviews__content {
    flex-direction: column;
  }

  .reviews--testimonials .reviews__desc {
    max-width: 100%;
    margin-bottom: 2.25rem;
    margin-right: 0;
  }

  .reviews--testimonials h2 {
    margin-bottom: 16px;
  }

  .reviews--testimonials .reviews__items {
    grid-template-columns: repeat(2, 1fr);
    gap: 2.25rem 16px;
    display: grid;
  }

  .reviews--testimonials .reviews__item:not(:last-of-type) {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 576px) {
  .reviews--testimonials .reviews__items {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 475px) {
  .reviews__statistics {
    width: 100%;
    gap: 2.25rem 18px;
  }
}

.faq h1 {
  color: var(--color-dark);
  margin-bottom: 3rem;
  font-size: clamp(34px, 3.5rem + 1vi, 56px);
  line-height: 1.23;
}

.faq__item:not(:last-child) {
  border-bottom: 1px solid var(--color-dark);
  margin-bottom: 32px;
}

.faq details {
  overflow: hidden;
}

.faq details[open] summary:before {
  rotate: 180deg;
}

.faq summary {
  cursor: pointer;
  padding: 0 5.375rem 12px 0;
  position: relative;
}

.faq summary:before {
  content: "";
  border-radius: var(--radius-main);
  width: 32px;
  height: 32px;
  background-image: var(--icon-arrow);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px 10px;
  background-color: var(--color-white);
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

.faq summary + div {
  padding: 0 0 24px;
}

.faq h2 {
  text-align: center;
  margin: 0 auto 28px;
}

.faq a {
  margin: 0 auto;
}

@media only screen and (max-width: 992px) {
  .faq h1 {
    font-size: clamp(32px, 2rem + 1vi, 44px);
  }

  .faq summary {
    padding-right: 2.25rem;
  }
}

.request {
  background-color: var(--color-gray-224);
}

.request h2 {
  text-align: center;
  margin-bottom: 3rem;
}

.request__form {
  max-width: 556px;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  display: flex;
}

.request__form label:not(:last-of-type) {
  margin-bottom: 24px;
}

.request__form label:last-of-type {
  margin-bottom: 12px;
}

.request input, .request textarea {
  width: 100%;
  color: var(--color-dark);
  border-bottom: 1px solid var(--color-gray-200);
  padding: .75rem 0;
  display: inline-block;
}

.request input::placeholder, .request textarea::placeholder {
  color: var(--color-gray-200);
}

.request textarea {
  min-height: 6rem;
  resize: none;
  background-color: #0000;
  border-top: none;
  border-left: none;
  border-right: none;
}

.request .btn {
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .request .btn {
    max-width: 100%;
    margin: 0;
  }
}

.policy h1 {
  color: var(--color-dark);
  margin-bottom: 3rem;
  font-size: clamp(34px, 3.5rem + 1vi, 56px);
  line-height: 1.23;
}

.policy .title {
  margin-bottom: 2.25rem;
}

.policy p:not(:last-of-type) {
  margin-bottom: 16px;
}

.policy a {
  font-weight: var(--fw-600);
  color: var(--color-gray-200);
  text-decoration: underline;
}

.policy ul {
  margin-bottom: 2.25rem;
  font-weight: 500;
}

.policy li {
  padding-left: 30px;
  position: relative;
}

.policy li:before {
  content: "✓";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.policy li:not(:last-child) {
  margin-bottom: 12px;
}

@media only screen and (max-width: 992px) {
  .policy h1 {
    margin-bottom: 2.25rem;
    font-size: clamp(32px, 2rem + 1vi, 44px);
  }
}

.footer {
  padding: 5.375rem 0 0;
}

.footer .nav {
  border-top: 1px solid var(--color-gray-200);
  border-bottom: 1px solid var(--color-gray-200);
  padding: 3rem 0;
}

.footer .nav__inner {
  max-width: 100%;
  justify-content: flex-end;
}

.footer .nav__list {
  margin-right: 0;
}

.footer .nav__link {
  color: var(--color-dark);
}

.footer .nav__link:hover, .footer .nav__link.true {
  border-color: var(--color-dark);
}

.footer__inner {
  display: none;
}

.footer__list {
  gap: 40px;
  display: flex;
}

.footer__list a {
  font-size: 14px;
}

.footer__bottom {
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .footer {
    padding: 0;
  }

  .footer .nav, .footer__bottom {
    text-align: center;
    padding: 20px 0;
  }

  .footer__inner {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .footer__bottom {
    flex-direction: column;
    gap: 20px;
  }

  .footer .logo {
    max-width: 85px;
    margin-right: 20px;
  }

  .footer__list {
    gap: 10px;
  }
}

.projects {
  overflow: hidden;
}

.projects h2 {
  margin-bottom: 2.25rem;
}

.projects .btn {
  margin-inline: 0;
}

.projects__list {
  max-width: 96vw;
  width: 100%;
  border-bottom: 1px solid var(--color-gray-250);
  grid-template-columns: repeat(4, 400px);
  grid-auto-rows: 1fr;
  align-items: stretch;
  gap: 16px;
  margin-left: 3.75rem;
  padding-block: 3.375rem 2.25rem;
  display: grid;
  overflow-x: scroll;
}

.projects .overlay:before {
  z-index: -1;
  background: var(--gradient-secondary);
}

.projects__article {
  position: relative;
}

.projects__article a:before {
  content: "";
  z-index: 2;
  position: absolute;
  inset: 0;
}

.projects__content {
  padding: 0 1.5rem 1.375rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.projects__name {
  color: var(--color-gray-100);
  margin-bottom: 4px;
}

@media only screen and (min-width: 1601px) {
  .projects__list {
    max-width: 1350px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 992px) {
  .projects h2 {
    margin-bottom: 1.125rem;
  }

  .projects__list {
    margin-left: 2.25rem;
    padding: 2.25rem 0;
  }
}

@media only screen and (max-width: 475px) {
  .projects__list {
    grid-template-columns: repeat(4, 250px);
    margin-left: 16px;
  }

  .projects__img {
    height: auto;
  }
}

.care h2 {
  margin-bottom: 12px;
}

.care__desc {
  margin-bottom: 3rem;
}

.care__items {
  margin-bottom: 3rem;
  display: flex;
}

.care__item {
  max-width: 660px;
  width: 100%;
}

.care__item:not(:last-of-type) {
  padding-right: 3rem;
}

.care__item:last-of-type {
  border-left: 1px solid var(--color-dark);
  padding-left: 3rem;
}

.care__item p:not(:last-of-type) {
  margin-bottom: 1rem;
}

.care h3 {
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 992px) {
  .care__desc, .care__items {
    margin-bottom: 2.25rem;
  }

  .care__items {
    flex-direction: column;
  }

  .care__item {
    max-width: 100%;
  }

  .care__item:not(:last-of-type) {
    padding-bottom: 2.25rem;
    padding-right: 0;
  }

  .care__item:last-of-type {
    border-left: none;
    border-top: 1px solid var(--color-dark);
    padding-top: 2.25rem;
    padding-left: 0;
  }
}

.contacts__items {
  justify-content: space-between;
  display: flex;
}

.contacts__content {
  max-width: 597px;
  width: 100%;
}

.contacts__image {
  max-width: 667px;
  width: 100%;
  margin-right: 24px;
}

.contacts__image img {
  object-fit: contain;
}

.contacts__info {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.contacts__info:not(:last-of-type) {
  border-bottom: 1px solid var(--color-gray-250);
  margin-bottom: 2.25rem;
  padding-bottom: 2.25rem;
}

.contacts__name {
  margin-right: 2.25rem;
}

@media only screen and (max-width: 768px) {
  .contacts__items {
    flex-direction: column;
  }

  .contacts__image {
    margin-bottom: 2.75rem;
    margin-right: 0;
  }

  .contacts__content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 475px) {
  .contacts__info {
    flex-direction: column;
    align-items: flex-start;
  }

  .contacts__info:not(:last-of-type) {
    padding-bottom: 16px;
  }

  .contacts__name {
    margin-bottom: 16px;
    margin-right: 0;
  }
}

/*# sourceMappingURL=main.css.map */
