@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.services {
  h2 {
    margin-bottom: rem(56);
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    align-items: stretch;
    justify-items: stretch;
    gap: 16px;
    margin-bottom: rem(56);
    padding-top: rem(36);
    border-top: 1px solid var(--color-gray-250);
  }

  &__article {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 450px;
    padding: rem(24);
  }

  .overlay {
    &::before {
      z-index: -1;
    }
  }

  &__img {
    position: absolute;
    inset: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
  }

  &__name {
    position: absolute;
    top: rem(24);
    right: rem(24);
    padding: 4px 16px;
    font-size: 16px;
    line-height: 1.4;
    font-weight: var(--fw-300);
    color: var(--color-gray-100);
    border: 1px solid var(--color-gray-100);
    border-radius: var(--radius-main);
  }

  &__object {
    margin-bottom: 8px;
    color: var(--color-gray-100);
  }

  .desc {
    color: var(--color-gray-100);
  }

  &__link {
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 2;
    }
  }

  &--projects,
  &--project,
  &--contacts {
    padding: rem(86) 0;
  }

  &--projects {
    .overlay {
      &::before {
        background: var(--gradient-services);
        z-index: -1;
      }
    }
  }

  &--project {
    .services__grid {
      margin-bottom: 0;
    }

    .services__img {
      position: static;
    }
  }

  &--contacts {
    h2 {
      width: 90%;
      margin-bottom: 12px;
      padding-top: rem(36);
      line-height: 1.19;
      letter-spacing: rem(0.8);
      border-top: 1px solid var(--color-gray-250);
    }

    .desc {
      margin-bottom: rem(36);
    }

    .btn {
      margin: 0;
    }
  }

  &--contacts {
    h2 {
      width: 90%;
      margin-bottom: 12px;
      padding-top: rem(36);
      line-height: 1.19;
      letter-spacing: rem(0.8);
      border-top: 1px solid var(--color-gray-250);
    }

    .desc {
      margin-bottom: rem(36);
      color: var(--color-dark);
    }

    .btn {
      margin: 0;
    }
  }

  @include media(tablet) {
    h2 {
      margin-bottom: rem(18);
    }

    &--projects,
    &--project,
    &--contacts {
      padding: rem(44) 0;
    }

    &--project {
      padding-top: 0;

      .services__img {
        height: auto;
        aspect-ratio: 5 / 3
      }
    }

    &--contacts {
      h2 {
        width: 100%;
      }
    }
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: 1fr;
      margin-bottom: rem(36);
    }
  }

  @include media(mobile-s) {
    &__article {
      min-height: 350px;
      padding: rem(86) 16px 16px;
    }
  }
}