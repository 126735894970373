@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    height: auto;
  }

  .logo {
    max-width: 140px;
    width: 100%;
    margin-right: rem(60);
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    max-width: 943px;
    width: 100%;

    @include media(tablet) {
      position: fixed;
      left: 0;
      top: calc(var(--header-height) * 1);
      z-index: -1;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      padding: 30px 16px;
      max-width: 100%;
      height: calc(var(--vh));
      background-color: var(--color-dark);
      transform: translateX(150%);
      transition-property: transform;
      transition-duration: 0.4s;
    }

    @include media(mobile-s) {
      padding: 16px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    margin: 0 20px 0 0;

    @include media(tablet) {
      z-index: -1;
      flex-direction: column;
      margin-right: 0;
    }
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: rem(38);

      @include media(tablet) {
        margin-right: 0;
      }
    }

    @include media(tablet) {
      margin-bottom: 30px;
    }
  }

  &__link {
    color: var(--color-gray-100);
    text-transform: uppercase;
    transition: border-bottom 0.3s ease-in-out;

    &.true,
    &:hover {
      border-bottom: 1px solid var(--color-gray-100);
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: rem(24);

    @include media(tablet) {
      flex-direction: column;
      gap: 30px;
    }
  }
}