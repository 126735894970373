@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.about {
  padding: rem(128) 0 rem(86);

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__intro {
    max-width: 573px;
    width: 100%;
    margin-right: rem(30);
  }

  h2 {
    margin-bottom: rem(36);
  }

  &__slider {
    max-width: 470px;
    width: 100%;
    margin: 0;
  }

  &__block {
    &:not(:last-of-type) {
      margin-bottom: rem(48);
    }

    h2 {
      position: relative;
      width: fit-content;
      margin-bottom: 0;
      padding-left: 0;
      transition-property: padding-left, margin-bottom;
      transition-duration: 0.6s;
      transition-timing-function: ease-in-out;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        height: 2px;
        width: 76px;
        background-color: var(--color-dark);
        transform: translateY(-50%) translateX(-100%);

        transition-property: transform;
        transition-duration: 0.4s;
        transition-timing-function: ease-in;
      }
    }

    h2:hover {
      margin-bottom: 16px;
      padding-left: 100px;
      transition-duration: 0.4s;

      &::before {
        background-color: var(--color-dark);
        transform: translateY(-50%) translateX(0);
        transition-duration: 0.6s;
      }
    }

    .about__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: rem(48);
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition-property: max-height, opacity;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      transition-delay: 0.4s;

      img {
        opacity: 0;
        transition: opacity 1.2s ease-in-out;
      }
    }

    h2:hover + .about__content {
      max-height: 1000px;
      visibility: visible;
      opacity: 1;
      transition-duration: 0.8s;

      img {
        opacity: 1;
      }
    }

    @include media(tablet) {
      &:not(:last-of-type) {
        margin-bottom: rem(36);
      }

      h2 {
        position: relative;
        width: fit-content;
        padding-left: 60px;
        margin-bottom: rem(24);
        transition: none;

        &::before {
          width: 50px;
          background-color: var(--color-dark);
          transform: translateY(-50%) translateX(0);
          transition: none;
        }
      }

      h2:hover {
        margin-bottom: rem(24);
        padding-left: 60px;
        transition: none;

        &::before {
          transform: translateY(0) translateX(0);
          transition: none;
        }
      }

      .about__content {
        gap: rem(24);
        max-height: 100%;
        opacity: 1;
        overflow: visible;
        transition: none;

        img {
          opacity: 1;
          transition: none;
        }
      }

      h2:hover + .about__content {
        transition: none;
      }
    }

    @include media(mobile-l) {
      .about__content {
        flex-direction: column;
      }

      img {
        width: 100%;
        height: auto;
        aspect-ratio: 4/2;
      }
    }

    @include media(mobile-s) {
      h2 {
        padding-left: 16px;
        padding-bottom: 10px;

        &::before {
          bottom: 0;
          top: unset;
          transform: translateY(0);
          width: 55%;
        }
      }
    }
  }

  &--services {
    padding: rem(48) 0;

    &#sheds {
      padding-bottom: rem(86);
    }

    border-top: 1px solid var(--color-gray-250);

    .about__intro {
      max-width: 722px;
    }

    .about__texts {
      p:not(:last-of-type) {
        margin-bottom: rem(36);
      }
    }
  }

  &--hovered {
    padding: rem(56) 0;
  }

  @include media(tablet) {
    padding: rem(44) 0;

    br {
      display: none;
    }

    &__inner {
      flex-direction: column;
    }

    &__intro,
    &__image,
    &__img {
      max-width: 100%;
      width: 100%;
    }

    &__intro {
      margin-right: 0;

      &:not(:last-of-type) {
        margin-bottom: rem(36);
      }
    }

    &__img {
      height: auto;
      aspect-ratio: 5/2;
    }

    &--services {
      .about__intro,
      .about__slider,
      .about__img {
        max-width: 100%;
        width: 100%;
      }

      &#sheds {
        padding-bottom: rem(44);
      }
    }
  }

  @include media(mobile-m) {
    &--services {
      .about__img {
        height: auto;
        aspect-ratio: 5/3;
      }
    }
  }
}