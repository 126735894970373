@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.main {
  @include media(tablet) {
    padding-top: var(--header-height);
  }
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  font-style: normal;
  font-size: clamp(16px, 1vw, 20px);
  line-height: 1.5;
  letter-spacing: 0;
  color: var(--color-gray-700);
  background-color: var(--color-gray-100);
  font-optical-sizing: auto;

  &__body {
    min-width: 320px;
    min-height: var(--vh);
  }

  &.open {
    overflow: hidden;
  }

  &--no-hero {
    .main {
      padding-top: var(--header-height);
    }

    .header:not(.scroll) {
      .logo__img {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(263deg) brightness(102%) contrast(103%);
      }

      .nav__link {
        color: var(--color-dark);

        &:hover,
        &.true {
          border-bottom: 1px solid var(--color-dark);
        }
      }

      @include media(tablet) {
        .logo__img {
          filter: none;
        }

        .nav__link {
          color: var(--color-gray-100);
        }
      }
    }
  }
}

section {
  padding: rem(86) 0;

  @include media(tablet) {
    padding: rem(44) 0;
  }
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.site-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  min-height: var(--vh);
}

.overlay {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    inset: 0;
    background: var(--gradient-main);
  }
}