@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  $root: &;
  padding: 0;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    padding: rem(194) 0;
    min-height: 900px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__content {
    z-index: 1;
    margin-inline: rem(60) 0;
  }

  h1 {
    margin-bottom: rem(36);
  }

  &__subtitle {
    margin-bottom: rem(195);
    color: var(--color-gray-100);
  }

  &__links {
    display: flex;
    justify-content: space-between;
    max-width: 1320px;
    width: 100%;
  }

  &__link {
    a {
      color: var(--color-gray-100);
      border-bottom: 1px solid transparent;
      transition: border-bottom 0.3s ease-in-out;

      &:hover {
        border-bottom: 1px solid var(--color-gray-100);
      }

      &:not(:last-of-type) {
        margin-right: 16px;
      }
    }
  }

  &--pages {
    .hero__inner {
      min-height: 650px;
      padding: rem(195) 0 rem(36);
    }

    h1 {
      margin-bottom: rem(16);
    }
  }

  &--services {
    padding-bottom: rem(128);
  }

  &--projects {
    .hero__inner {
      padding: rem(195) 0 rem(54);
    }
  }

  &--request {
    .hero__inner {
      min-height: 500px;
      padding: rem(166) 0 0;
      justify-content: flex-start;
    }

    h2 {
      margin-bottom: 16px;
      line-height: 1.2;
      color: var(--color-gray-100);
    }

    .btn {
      margin: 0;
      color: var(--color-gray-100);
      border-bottom-color: var(--color-gray-100);

      &:hover {
        color: var(--color-gray-100);
        border: 1px solid var(--color-gray-100);
      }
    }
  }

  &--project {
    .hero__inner {
      padding: rem(296) 0 rem(36);
      justify-content: space-between;
    }

    h1 {
      margin-bottom: rem(206);
    }

    .hero__links {
      justify-content: flex-start;
      gap: rem(86);
    }

    .name {
      color: var(--color-gray-100);
    }

    sup {
      font-size: 14px;
    }
  }

  @include media(tablet-wide) {
    &__inner {
      min-height: 450px;
      padding: rem(194) 16px rem(86);
    }

    &--pages,
    &--project {
      .hero__inner {
        min-height: 450px;
        padding: rem(194) 16px rem(86);
      }
    }
  }

  @include media(tablet) {
    &__inner {
      justify-content: center;
      padding: rem(60) 16px;
    }

    &__content {
      margin-left: 0;
    }

    &--pages {
      .hero__inner {
        justify-content: flex-end;
        padding: rem(60) 16px 24px;
      }
    }

    &--services {
      padding: 0;
    }

    &--request {
      .hero__inner {
        min-height: 350px;
        padding: rem(44) 16px;
        justify-content: center;
      }
    }

    &--project {
      .hero__inner {
        min-height: 250px;
        padding-top: rem(100);
      }

      .hero__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: inherit;
        padding: 0;
      }

      h1 {
        margin-bottom: 24px;
      }
    }
  }

  @include media(mobile-l) {
    &--project {
      .hero__links {
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: nowrap;
        gap: 16px;
      }

      .hero__link {
        width: auto;
      }

      .name {
        font-size: 14px;
      }
    }
  }

  @include media(mobile-m) {
    &__links {
      flex-wrap: wrap;
      gap: rem(18) 0;
    }

    &__link {
      width: 45%;
      text-align: center;
    }
  }
}