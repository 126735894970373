@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.projects {
  overflow: hidden;

  h2 {
    margin-bottom: rem(36);
  }

  .btn {
    margin-inline: 0;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 400px);
    grid-auto-rows: 1fr;
    align-items: stretch;
    gap: 16px;
    max-width: 96vw;
    width: 100%;
    margin-left: rem(60);
    padding-block: rem(54) rem(36);
    border-bottom: 1px solid var(--color-gray-250);
    overflow-x: scroll;
  }

  .overlay {
    &::before {
      z-index: -1;
      background: var(--gradient-secondary)
    }
  }

  &__article {
    position: relative;

    a::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 2;
    }
  }

  &__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 rem(24) rem(22);
  }

  &__name {
    margin-bottom: 4px;
  }

  &__name {
    color: var(--color-gray-100);
  }

  @include media(desktop-l, min) {
    &__list {
      max-width: 1350px;
      margin: 0 auto;
    }
  }

  @include media(tablet) {
    h2 {
      margin-bottom: rem(18);
    }

    &__list {
      margin-left: rem(36);
      padding: rem(36) 0;
    }
  }

  @include media(mobile-s) {
    &__list {
      grid-template-columns: repeat(4, 250px);
      margin-left: 16px;
    }

    &__img {
      height: auto;
    }
  }
}