@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

*[class].btn {
  display: inline-block;
  margin: 0;
  padding: 10px 24px;
  border: 2px solid var(--color-gray-200);
  color: var(--color-gray-200);
  text-align: center;
  border-radius: var(--radius-main);
  background-color: transparent;
  transition-property: background-color, color, border-color;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;

  &--reverse,
  &:hover {
    color: var(--color-gray-100);
    background-color: var(--color-gray-200);
  }

  &--reverse:hover {
    background-color: transparent;
    color: var(--color-gray-200);
  }

  &--underline {
    display: block;
    max-width: fit-content;
    width: 100%;
    margin: 0 auto;
    padding: 11px 0;
    font-size: 14px;
    line-height: 1.22;
    color: var(--color-dark);
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--color-dark);
    border-radius: 0;
    transition-property: border, padding;

    &:hover {
      padding: 11px;
      color: var(--color-dark);
      background-color: transparent;
      border: 1px solid var(--color-dark);
    }
  }
}

*[class].swiper {
  &-button {
    @include cover-background;

    position: static;
    width: 16px;
    height: 8px;
  }

  &-button-prev {
    background-image: url("../images/icons/arrow-prev.svg");
  }

  &-button-next {
    background-image: url("../images/icons/arrow-next.svg");
  }

  &-pagination {
    bottom: 16px;
    display: flex;
    justify-content: space-between;
    padding: 0 rem(28);
  }

  &-pagination-bullet {
    width: rem(90);
    height: 1px;
    border-radius: 0;
    background-color: var(--color-gray-100);
    opacity: 0.36;

    &-active {
      opacity: 1;
    }
  }
}