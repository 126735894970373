@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.request {
  $root: &;
  background-color: var(--color-gray-224);

  h2 {
    margin-bottom: rem(48);
    text-align: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    max-width: 556px;
    width: 100%;
    margin: 0 auto;

    label:not(:last-of-type) {
      margin-bottom: 24px;
    }

    label:last-of-type {
      margin-bottom: 12px;
    }

  }

  input,
  textarea {
    display: inline-block;
    width: 100%;
    padding: rem(12) 0 rem(12) 0;
    color: var(--color-dark);
    border-bottom: 1px solid var(--color-gray-200);


    &::placeholder {
      color: var(--color-gray-200)
    }
  }

  textarea {
    min-height: rem(96);
    background-color: transparent;
    resize: none;
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .btn {
    max-width: fit-content;
    margin: 0 auto;
  }

  @include media(mobile-l) {
    .btn {
      max-width: 100%;
      margin: 0;
    }
  }
}