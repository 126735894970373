@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.faq {

  h1 {
    margin-bottom: rem(48);
    font-size: clamp(34px, 3.5rem + 1vi, 56px);
    line-height: 1.23;
    color: var(--color-dark);
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 32px;
      border-bottom: 1px solid var(--color-dark);
    }
  }

  details {
    overflow: hidden;

    &[open] {
      summary::before {
        rotate: 180deg;
      }
    }
  }

  summary {
    position: relative;
    padding: 0 rem(86) 12px 0;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      overflow: hidden;
      border-radius: var(--radius-main);
      width: 32px;
      height: 32px;
      background-image: var(--icon-arrow);
      background-position: center;
      background-size: 18px 10px;
      background-repeat: no-repeat;
      background-color: var(--color-white);
    }

    + div {
      padding: 0 0 24px 0;
    }
  }

  h2 {
    margin: 0 auto 28px;
    text-align: center;
  }

  a {
    margin: 0 auto;
  }

  @include media(tablet) {
    h1 {
      font-size: clamp(32px, 2rem + 1vi, 44px);
    }

    summary {
      padding-right: rem(36);
    }
  }
}